import React, { useEffect, useState } from 'react';
import {
    useParams as navigateParam,
    useHistory
} from "react-router-dom";

import moment from 'moment';

import ContentNavComponent from '../../application/components/contentNav.component';
import ConfirmModalComponent from '../../application/components/confirmModal.component';
import RejectAcceptanceModalComponent from './rejectAcceptanceModal.component';

import BeeIcon from '../../application/assets/img/bee_icon.svg';
import ViewsIcon from '../../application/assets/img/views.png';

function OfferDetailsComponent(props) {

    let { offerId } = navigateParam();

    const history = useHistory();
    const { getOfferDetails, clearOfferDetails, offerDetails, offerAcceptance } = props;
    const [selectedOfferId, setSelectedOfferId] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);

    useEffect(() => {
        getOfferDetails(offerId);
        return () => {
            clearOfferDetails();
        }
    }, [getOfferDetails, offerId, clearOfferDetails]);

    if (!offerDetails) {
        return null;
    }

    const hrefs = [{
        label: "Akceptacja ofert",
        route: "/admin/offers"
    },
    {
        label: `#${offerDetails.offerNumber}`,
        route: `/admin/offer/${offerId}`
    }];

    function handleAcceptanceOffer() {
        toggleModal(); // zamykamy modal

        offerAcceptance(selectedOfferId, {
            status: 3
        }); // akceptujemy wizytówke

        setSelectedOfferId(false); // zerujemy ID

        setTimeout(() => {
            history.push("/admin/offers", { withoutReload: true });
        }, 200);
    }

    function handleRejectAcceptanceOffer(reson) {
        toggleRejectModal(); // zamykamy modal

        offerAcceptance(selectedOfferId, {
            status: 2,
            rejectReason: reson
        }); // akceptujemy wizytówke

        setSelectedOfferId(false); // zerujemy ID

        setTimeout(() => {
            history.push("/admin/acceptance", { withoutReload: true });
        }, 200);
    }

    function toggleModal(offerId) {
        setSelectedOfferId(offerId ? offerId : false);
        setShowModal(!showModal);
    }

    function toggleRejectModal(offerId) {
        setSelectedOfferId(offerId ? offerId : false);
        setShowRejectModal(!showRejectModal);
    }

    return (
        <div>
            <ContentNavComponent hrefs={hrefs} />
            <section id="singleCardContent" className="single-card__content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-5 mb-5 mb-xl-0">
                            <div className="single-card__info bg-white p-5">
                                <div className="row justify-content-between align-items-center mb-5">
                                    <div className="col-12 col-md-auto">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-auto">
                                                <div className="hex__icon zi-1">
                                                    <img src={BeeIcon} alt="" className="w-100" />
                                                </div>
                                            </div>
                                            {/* NUMER OFERTY */}
                                            <div className="col-12 col-md-auto text-center mt-4 mt-md-0">
                                                <p className="single-card__id">#{offerDetails.offerNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* LICZBA WYŚWIETLEŃ */}
                                    <div className="col-12 col-md-auto">
                                        <div className="single-card__views text-right mt-4 mt-md-0">
                                            <span className="font-weight-bold mr-2">{offerDetails.views}</span>
                                            <img src={ViewsIcon} alt="" />
                                        </div>
                                    </div>
                                </div>

                                {/* KATEGORIE */}
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__category">
                                            <h3 className="mb-2">Kategorie</h3>
                                            <ul className="dot-list">
                                                {offerDetails.categories.map((category, index) => {
                                                    return <li key={`card-catgory-${index}`}>{category.categoryBreadcrumbs}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* STANOWISKO */}
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__position">
                                            <h3 className="mb-2">Stanowisko</h3>
                                            <p className="font-weight-600">{offerDetails.position}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* RODDZAJ UMOWY */}
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__deal">
                                            <h3 className="mb-2">Rodzaj umowy</h3>
                                            <p className="font-weight-600">{offerDetails.typeOfContract}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* WOJEWODZTWO */}
                                {!offerDetails.address.wholeCountry && offerDetails.address.administrative_areas_level_1 && offerDetails.address.administrative_areas_level_1.length > 0 && <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__province">
                                            <h3 className="mb-2">Województwo</h3>
                                            <p className="font-weight-600">{offerDetails.address.administrative_areas_level_1.join(", ")}</p>
                                        </div>
                                    </div>
                                </div>}

                                {/* MIASTO */}
                                {!offerDetails.address.wholeCountry && offerDetails.address.cities && offerDetails.address.cities.length > 0 && <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__city">
                                            <h3 className="mb-2">Miasto</h3>
                                            <p className="font-weight-600">{offerDetails.address.cities.join(", ")}</p>
                                        </div>
                                    </div>
                                </div>}

                                {/* CAŁY KRAJ */}
                                {(offerDetails.address.wholeCountry || offerDetails.address.outsiteCountry) && <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__city">
                                            <h3 className="mb-2">Lokalizacja</h3>
                                            {offerDetails.address.wholeCountry && <p className="card-text">Cały kraj</p>}
                                            {offerDetails.address.outsiteCountry && <p className="card-text">Praca za granicą</p>}
                                        </div>
                                    </div>
                                </div>}
                            </div>

                            {/* O FIRMIE */}
                            <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">O firmie</h3>
                                <p className="font-weight-600" dangerouslySetInnerHTML={{ __html: offerDetails.companyDescription.replace(/(<? *script)/gi, 'illegalscript') }}></p>
                            </div>

                            {/* OPIS STANOWISKA */}
                            <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Opis stanowiska</h3>
                                <p className="font-weight-600" dangerouslySetInnerHTML={{ __html: offerDetails.positionDescription.replace(/(<? *script)/gi, 'illegalscript') }} ></p>
                            </div>

                            {/* OFERUJEMY */}
                            <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Oferujemy</h3>
                                <p className="font-weight-600" dangerouslySetInnerHTML={{ __html: offerDetails.benefits.replace(/(<? *script)/gi, 'illegalscript') }}></p>
                            </div>

                            {/* WYMAGANIA */}
                            <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Wymagania</h3>
                                <p className="font-weight-600" dangerouslySetInnerHTML={{ __html: offerDetails.requirements.replace(/(<? *script)/gi, 'illegalscript') }}></p>
                            </div>

                            {/* UMIEJETNOSCI */}
                            <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Wymagane umiejętności</h3>
                                <ul className="dot-list">
                                    {offerDetails.requiredSkills.map((skill, index) => {
                                        return <li key={`card-catgory-${index}`}>{skill}</li>
                                    })}
                                </ul>
                            </div>

                            {/* JĘZYKI */}
                            <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Języki</h3>
                                <ul className="dot-list">
                                    {offerDetails.languages.map((lang, index) => {
                                        return <li key={`card-catgory-${index}`}>{lang}</li>
                                    })}
                                </ul>
                            </div>

                            {/* POWODY ODRZUCEŃ */}
                            {offerDetails.rejectHistory && offerDetails.rejectHistory.length > 0 && <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Powody odrzuceń</h3>
                                {offerDetails.rejectHistory.map((rejectInfo, index) => {
                                    return <p key={`card-lang-${index}`} className="card-text single-category">{moment(rejectInfo.createdAt).format('llll')} - {rejectInfo.reason}</p>
                                })}
                            </div>}

                            {/* DANE KONTAKTOWE */}
                            {offerDetails.recruiter && <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Dane kontaktowe z rekruterem</h3>
                                <p className="card-text">Imie: {offerDetails.recruiter.name}</p>
                                <p className="card-text">Nazwisko: {offerDetails.recruiter.surname}</p>
                                <p className="card-text">Kontakt: {offerDetails.recruiter.contact}</p>
                            </div>}

                            {/* AKCEPTACJA */}
                            {offerDetails.status === 1 && <div className="single-card__info bg-white p-5 mt-4">
                                <div className="row justify-content-center">
                                    <div type="button" className="col-auto text-center">
                                        <button className="btn btn--primary" onClick={() => toggleModal(offerDetails._id)}>Akceptuj</button>
                                    </div>
                                    <div className="col-auto text-center">
                                        <button type="button" className="btn btn--outline h-100" data-dismiss="modal" aria-label="Close" onClick={() => toggleRejectModal(offerDetails._id)}>Odrzuć</button>
                                    </div>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </section>
            <ConfirmModalComponent show={showModal} handleSubmit={handleAcceptanceOffer} handleToggleModal={toggleModal} contentText="Czy na pewno chcesz zaakceptować ofertę?" submitText="TAK" />
            <RejectAcceptanceModalComponent show={showRejectModal} handleSubmit={handleRejectAcceptanceOffer} handleToggleModal={toggleRejectModal} />
        </div >
    )

}

export default OfferDetailsComponent;
