import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import ConfirmModalComponent from '../../application/components/confirmModal.component';

import ContentnavComponent from '../../application/components/contentNav.component';

import BeeIcon from '../../application/assets/img/bee_icon.svg';
import ViewsIcon from '../../application/assets/img/views.png';

import moment from 'moment';
moment.locale('pl');

function OffersComponent(props) {

   let history = useHistory();
   const { getOffers, offers, deleteOffer, refreshOffer, clearOffers, isLoading, changeStatus, setMatches } = props;

   const [deleteOfferId, setDeleteOfferId] = useState(false);
   const [showConfirmModal, setShowConfirmModal] = useState(false);

   useEffect(() => {
      getOffers();
      return () => { // componentUnmount
         clearOffers();
      }
   }, [getOffers, clearOffers]);


   const handleEditOffer = (offerId) => {
      history.push(`/offer/${offerId}`);
   }

   function handleRefreshOffer(offerId) {
      refreshOffer(offerId);
   }

   function handleDeleteOffer() {
      deleteOffer(deleteOfferId);
      toggleModal();
   }

   function toggleModal(offerId) {
      setDeleteOfferId(offerId ? offerId : false);
      setShowConfirmModal(!showConfirmModal);
   }

   function goToMatches(matches) {
      setMatches(matches, "cards");
      history.push("/kandydaci");
   }


   function getStatusDescription(status) {
      switch (status) {
         case 1:
         case "1":
            return "Twoja oferta oczekuje na akceptację. Do czasu zaakceptowania nie jest widoczna w wynikach wyszukiwania.";
         case 2:
         case "2":
            return "Twoja oferta została odrzucona w procesie weryfikacji i wymaga edycji aby pojawić się w wynikach wyszukiwania";
         case 3:
         case "3":
            return "Twoja oferta przeszła proces weryfikacji i jest widoczna w wynikach wyszukiwania."
         default:
            return "Nieznany status";
      }
   }

   return (
      <div>
         <ContentnavComponent />
         <section className="my-card__content">
            <div className="container-fluid">
               <div className="row">
                  {offers.map((offer, index) => {
                     const activeButton = offer.active ?
                        (<button onClick={() => changeStatus(offer._id, !offer.active)} className="btn btn--secondary w-100">Dezaktywuj </button>)
                        :
                        (<button onClick={() => changeStatus(offer._id, !offer.active)} className="btn btn--secondary w-100"> Aktywuj </button>);
                     const isActivatedClass = !offer.active ? "card-deactivated" : "";
                     const statusInfo = offer.status === 1 ? <span className="offer-for-acceptance">wysłano do akceptacji</span> : offer.status === 2 ? <span className="offer-reject">odrzucono</span> : <span className="offer-accepted">zaakceptowano</span>;
                     const statusDescription = getStatusDescription(offer.status);

                     return <div key={'offer-' + index} className="col-12 col-lg-6 col-xl-4 mb-5">
                        <div className="my-card__info bg-white p-5">
                           <div className={isActivatedClass}>
                              {/* HEADER */}
                              <div className={`row justify-content-center justify-content-sm-between align-items-center mb-5 ${isActivatedClass}`}>
                                 <div className="col-auto">
                                    <div className="row align-items-center">
                                       <div className="col-12 col-md-auto">
                                          <div className="hex__icon zi-1">
                                             <img src={BeeIcon} alt="" className="w-100" />
                                          </div>
                                       </div>
                                       <div className="col-12 col-md-auto text-center mt-4 mt-md-0">
                                          <p className="my-card__id">#{offer.offerNumber}</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-12 col-md-auto">
                                    <div className="my-card__views text-right mt-4 mt-md-0">
                                       <span className="font-weight-bold mr-2">{offer.views}</span>
                                       <img src={ViewsIcon} alt="" />
                                    </div>
                                 </div>
                              </div>
                              {/* KATEGORIE */}
                              <div className="row mb-4">
                                 <div className="col-12">
                                    <div className="my-card__category">
                                       <h3 className="mb-2">Dział</h3>
                                       <ul className="dot-list">
                                          {offer.categories.map((category, index) => {
                                             return <li key={`card-catgory-${index}`} >{category.categoryBreadcrumbs}</li>
                                          })}
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                              {/* STANOWISKO */}
                              <div className="row mb-4">
                                 <div className="col-12">
                                    <div className="my-card__position">
                                       <h3 className="mb-2">Stanowisko</h3>
                                       <p className="font-weight-600">{offer.position}</p>
                                    </div>
                                 </div>
                              </div>
                              {/* DATA WYGASNIECIA */}
                              <div className="row mb-4">
                                 <div className="col-12">
                                    <div className="my-card__expire">
                                       <h3 className="mb-2">Data wygaśnięcia oferty</h3>
                                       <p className="font-weight-600">{moment(offer.activeUntil).format('LLLL')}</p>
                                    </div>
                                 </div>
                              </div>
                              {/* STATUS */}
                              <div className="row mb-4">
                                 <div className="col-12">
                                    <div className="my-card__status my-card__status--waiting">
                                       <h3 className="mb-2">Status</h3>
                                       <span className="font-weight-600 mr-3">{statusInfo}</span>
                                       <a href="#!" className="info" data-toggle="tooltip" data-placement="top" title={statusDescription}>?</a>
                                    </div>
                                    {offer.status === 2 && <div className="card-text positon-text"><b>Powód:</b> {offer.rejectReason}</div>}
                                 </div>
                              </div>
                              {/* REKOMENDOWANE OFERTY */}
                              <div className="row mb-5">
                                 <div className="col-12">
                                    <div className="my-card__recommended">
                                       <button
                                          className="btn btn--primary w-100"
                                          type="submit"
                                          disabled={offer.matches && offer.matches.length > 0 ? false : true}
                                          onClick={() => goToMatches(offer.matches)}>
                                          Rekomendowani kandydaci ({offer.matches ? offer.matches.length : 0})
                                                    </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="row mb-4">
                              <div className="col-md-6 mb-4 mb-md-0">
                                 <div className="my-card__deactivate">
                                    {activeButton}
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="my-card__refresh">
                                    <button className="btn btn--primary w-100" type="submit" onClick={() => handleRefreshOffer(offer._id)}>Odśwież</button>
                                 </div>
                              </div>
                           </div>
                           <div className="row mb-4">
                              <div className="col-md-6 mb-4 mb-md-0">
                                 <div className="my-card__edit">
                                    <button className="btn btn--primary w-100" type="submit" onClick={() => handleEditOffer(offer._id)}>Edytuj</button>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="my-card__delete">
                                    <button className="btn btn--light w-100" type="submit" onClick={() => toggleModal(offer._id)}>Usuń</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  })}
                  {offers.length === 0 && !isLoading && <Container>
                     <Row className="justify-content-center">
                        <div className="no-element-found">Nie znaleziono twoich ofert</div>
                     </Row>
                  </Container>}
               </div>
            </div>
         </section>
         <ConfirmModalComponent show={showConfirmModal} handleToggleModal={toggleModal} handleSubmit={handleDeleteOffer} contentText="Czy na pewno chcesz usunąć wizytówkę?" submitText="USUŃ" />
      </div>
   )
}

export default OffersComponent;
